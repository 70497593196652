<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "HolidaysIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("holidayschedules", {
      contents: state => state.accountHolidaysMap
    })
  },

  methods: {
    initContent() {
      return {
        schedule_name: "",
        schedule_items: [],
        schedule_time_zone: "",
        is_archived: 0
      };
    }
  },

  mounted() {
    this.contentType = "HolidaySchedule";
    this.primaryKey = "schedule_id";
  }
};
</script>

<style lang="scss" scoped></style>
