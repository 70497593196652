var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-component",on:{"click":_vm.handleClearSelection}},[_c('el-button',{staticClass:"createBtn",attrs:{"disabled":!_vm.can('content.' + _vm.additionalValidateRoute + '.write')},on:{"click":function($event){$event.stopPropagation();return _vm.createContentItem.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.__("Create Holiday")))]),_c('div',{staticStyle:{"width":"100%"}},[_c('el-main',{staticStyle:{"margin-top":"136px"}},[_c('el-form',{staticClass:"form-container"},[_c('PaginationToolbar',{attrs:{"sort-by-options":_vm.sortByOptions,"show-content-view-switcher":true,"content-api":_vm.contentAPI}})],1),_c('el-scrollbar',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"native":true}},[_c('div',{staticClass:"contentCardsContainer"},[(_vm.holidaySchedules.length)?_c('el-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.can('content.' + _vm.additionalValidateRoute + '.read')),expression:"can('content.' + additionalValidateRoute + '.read')"}],class:_vm.contentViewClasses},_vm._l((_vm.holidaySchedulesForTableView),function(schedule,index){return _c('content-list-item',{key:'_content_schedule_' + index,attrs:{"content-view-type":_vm.contentViewType,"item-index":index,"draggable-data":{
                content_id: _vm.selectedItems.length
                  ? _vm.selectedIds
                  : [schedule[_vm.primaryKey]],
                content_type: _vm.contentType
              },"allow-multi-select":true,"card-class":{ selected: _vm.selectedItemIndex === index },"icon":"icon-holiday.svg","card-text":schedule.schedule_name,"content-list-item":schedule,"fields-to-display":_vm.displayFields,"is-all-selected":_vm.isAllSelected,"total-items-selected":_vm.selectedItems.length},on:{"delete":function($event){return _vm.handleDelete(
                  schedule,
                  'HolidaySchedule',
                  schedule.schedule_id
                )},"select":function($event){return _vm.handleSelect(index, schedule)},"edit":function($event){return _vm.handleEdit(index, schedule)},"checked-content":function($event){_vm.handleSelectMultiple($event)(schedule)},"toggle-select-all":function($event){return _vm.handleToggleSelectAll(_vm.holidaySchedules, ...arguments)}}})}),1):(!_vm.loading)?_c('el-row',{staticStyle:{"padding-top":"100px"},attrs:{"type":"flex","justify":"center"}},[_vm._v(" "+_vm._s(_vm.__("No Holiday Schedules"))+" ")]):_vm._e()],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }