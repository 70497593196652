<template>
  <div class="content-component" @click="handleClearSelection">
    <el-button
      :disabled="!can('content.' + additionalValidateRoute + '.write')"
      class="createBtn"
      @click.stop="createContentItem"
      >{{ __("Create Holiday") }}</el-button
    >
    <div style="width: 100%">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :show-content-view-switcher="true"
            :content-api="contentAPI"
          ></PaginationToolbar>
        </el-form>
        <el-scrollbar v-loading="loading" :native="true">
          <div class="contentCardsContainer">
            <el-row
              :class="contentViewClasses"
              v-if="holidaySchedules.length"
              v-show="can('content.' + additionalValidateRoute + '.read')"
            >
              <content-list-item
                :content-view-type="contentViewType"
                v-for="(schedule, index) in holidaySchedulesForTableView"
                :item-index="index"
                :key="'_content_schedule_' + index"
                :draggable-data="{
                  content_id: selectedItems.length
                    ? selectedIds
                    : [schedule[primaryKey]],
                  content_type: contentType
                }"
                :allow-multi-select="true"
                :card-class="{ selected: selectedItemIndex === index }"
                icon="icon-holiday.svg"
                :card-text="schedule.schedule_name"
                @delete="
                  handleDelete(
                    schedule,
                    'HolidaySchedule',
                    schedule.schedule_id
                  )
                "
                :content-list-item="schedule"
                :fields-to-display="displayFields"
                @select="handleSelect(index, schedule)"
                @edit="handleEdit(index, schedule)"
                @checked-content="handleSelectMultiple($event)(schedule)"
                @toggle-select-all="
                  handleToggleSelectAll(holidaySchedules, ...arguments)
                "
                :is-all-selected="isAllSelected"
                :total-items-selected="selectedItems.length"
              ></content-list-item>
            </el-row>
            <el-row
              v-else-if="!loading"
              style="padding-top: 100px;"
              type="flex"
              justify="center"
            >
              {{ __("No Holiday Schedules") }}
            </el-row>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import ContentListItem from "@/views/build/content/mixins/ContentListItem";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";

export default {
  name: "HolidaysList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    ContentListItem
  },
  data() {
    return {
      additionalValidateRoute: "holidays",
      sortByColumns: ["schedule_name", "schedule_id"],
      isContent: true,
      viewMode: "allow_read",
      displayFields: [
        {
          field_name: "schedule_name",
          display_name: __("Name")
        },
        {
          field_name: "schedule_time_zone",
          display_name: __("Time Zone"),
          use_slot: true
        },
        {
          field_name: "schedule_list",
          display_name: __("Schedule"),
          use_slot: true
        }
      ]
    };
  },

  computed: {
    ...mapState("holidayschedules", {
      holidaySchedules: state => state.accountHolidaysMap,
      loading: state => state.loading
    }),

    selectedIds() {
      return _.map(this.selectedItems, this.primaryKey);
    },

    holidaySchedulesForTableView() {
      let schedulesForTableView = this.holidaySchedules;
      if (this.holidaySchedules) {
        schedulesForTableView = schedulesForTableView.reduce((prev, curr) => {
          curr["schedule_list"] = curr["schedule_items"]
            .map(item => item.item_name)
            .join(", ");
          prev.push(curr);
          return prev;
        }, []);
      }
      return schedulesForTableView;
    }
  },

  methods: {
    ...mapActions("holidayschedules", {
      contentAPI: "getHolidaySchedules"
    })
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";
</style>
