<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-if="multipleItemsSelectedCount" class="side-panel-selected-count">
      <p>
        {{ __("Selected") }}
        <el-tag>
          <strong>{{ multipleItemsSelectedCount }}</strong>
        </el-tag>
        {{ __("items") }}.
      </p>
    </div>
    <div v-else class="side-panel-content-info">
      <div class="field-group">
        <div class="titles">{{ __("Name") }}</div>
        <div class="details">{{ contentForm.schedule_name }}</div>
      </div>
      <HolidayScheduleInfo
        :schedule_time_zone="contentForm.schedule_time_zone"
        :schedule_items="contentForm.schedule_items"
      ></HolidayScheduleInfo>
    </div>
    <div style="margin-top: 20px;" class="side-panel-action-list">
      <el-dropdown
        @command="command => handleAction(command)"
        placement="bottom-start"
      >
        <el-button plain class="btn-plain">{{ __("Actions") }}</el-button>
        <el-dropdown-menu slot="dropdown" style="width: 200px;">
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount && !isArchivedStatus"
            :disabled="!can('content.holidays.write')"
            command="edit"
          >
            <span>{{ __("Edit") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            command="move"
            :disabled="!can('content.holidays.write')"
          >
            <span>{{ __("Move to folder") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="check_in_use"
            :disabled="!!contentFormInfo.is_refreshing"
          >
            <span>{{ __("Check in use") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="refresh"
          >
            <span>{{ __("Refresh check in use") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount && isArchivedStatus"
            :disabled="!can('content.holidays.write')"
            command="restore"
          >
            <span>{{ __("Restore") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="delete"
            :disabled="!can('content.holidays.write')"
          >
            <span>{{ __("Delete") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
      :modal="false"
    >
      <el-scrollbar :native="false">
        <div class="max-vh">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
            :disabled="isArchivedStatus"
          >
            <el-row type="flex" style="padding-top: 20px">
              <el-col :span="6"></el-col>
              <el-col :span="18">
                <page-header :title="__('Holiday')" :content-id="this.id" />
              </el-col>
            </el-row>
            <el-row>
              <el-col
                :xs="{ span: 22, offset: 1 }"
                :sm="{ span: 20, offset: 2 }"
                :md="{ span: 16, offset: 4 }"
                :lg="{ span: 12, offset: 6 }"
              >
                <el-form-item prop="schedule_name" :label="__('Name')">
                  <el-input v-model="contentForm.schedule_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col
                :xs="{ span: 22, offset: 1 }"
                :sm="{ span: 20, offset: 2 }"
                :md="{ span: 16, offset: 4 }"
                :lg="{ span: 12, offset: 6 }"
              >
                <el-form-item :label="__('Timezone')" prop="schedule_time_zone">
                  <timezone-dropdown
                    :default_timezone="contentForm.schedule_time_zone"
                    @timezone-changed="handleTimezoneChange"
                  /> </el-form-item
              ></el-col>
            </el-row>
            <div style="display: flex;margin-left: 25%;margin-bottom: 20px">
              <el-button
                type="primary"
                @click="handleAddScheduleItem"
                class="submitBtn"
                id="addScheduleButton"
                >{{ __("Add Schedule") }}
              </el-button>
            </div>
            <el-row>
              <el-col
                :xs="{ span: 22, offset: 1 }"
                :sm="{ span: 20, offset: 2 }"
                :md="{ span: 16, offset: 4 }"
                :lg="{ span: 12, offset: 6 }"
              >
                <el-row
                  type="flex"
                  v-for="(schedule_item, index) in contentForm.schedule_items"
                  :key="schedule_item.item_id"
                >
                  <el-col>
                    <el-form-item
                      :prop="`schedule_items[${index}]`"
                      :rules="rules.schedule_items"
                    >
                      <holiday-schedule-edit
                        :schedule-item="schedule_item"
                        @remove-schedule-item="handleRemoveScheduleItem(index)"
                        @update-schedule-item="
                          new_schedule_item =>
                            handleUpdateScheduleItem(index, new_schedule_item)
                        "
                      ></holiday-schedule-edit>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form>
          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px;margin-top: 20px"
          >
            <save-button
              v-if="!isArchivedStatus"
              :disabled="!contentUpdated || !can('content.holidays.write')"
              type="primary"
              @click="submitForm"
              class="submitBtn"
              :primaryKey="id"
              variant="AddUpdate"
            />
            <el-button @click="handleCancel" class="cancelBtn"
              >{{ __("Cancel") }}
            </el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { mapActions, mapState } from "vuex";
import HolidayScheduleEdit from "@/views/build/content/holidays/components/HolidayScheduleEdit";
import HolidayScheduleInfo from "@/views/build/content/holidays/components/HolidayScheduleInfo";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import _ from "lodash";
import TimezoneDropdown from "@/components/TimezoneDropdown.vue";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { validateHolidayScheduleName } from "@/api/holidayschedules";
//import { formatTimeStampWithTz } from "@/utils/time";
import {
  CHECK_IN_USE_ACTION,
  DELETE_ACTION,
  EDIT_ACTION,
  MOVE_ACTION,
  REFRESH_ACTION,
  RESTORE_ACTION
} from "@/constants/contents";
import moment from "moment-timezone";

export default {
  mixins: [BaseContentInfoPanel, BaseContent],
  components: {
    PageHeader,
    SaveButton,
    HolidayScheduleEdit,
    HolidayScheduleInfo,
    TimezoneDropdown
  },
  data() {
    let validateHolidayName = async (rule, value, callback) => {
      let hasError = false;
      await validateHolidayScheduleName(value)
        .then(res => {
          if (res.data.found && value !== this.contentFormInfo.schedule_name) {
            hasError = true;
          }
        })
        .catch(() => {
          hasError = false;
        });
      if (hasError) {
        callback(__("Holiday Schedule already exists"));
      } else {
        callback();
      }
    };
    const checkScheduleItems = (rule, value, callback) => {
      if (!value.item_name) {
        return callback(new Error(__("Please enter name")));
      }

      if (!value.from_date_time) {
        return callback(new Error(__("Please enter from date")));
      }

      if (!value.to_date_time) {
        return callback(new Error(__("Please enter to date")));
      }

      if (!value.is_repeat) {
        const fromDateTime = moment.tz(
          value.from_date_time,
          this.contentForm.schedule_time_zone
        );
        const currentDateTime = moment.tz(
          new Date(),
          this.contentForm.schedule_time_zone
        );

        if (fromDateTime.isValid() && currentDateTime.isValid()) {
          if (fromDateTime.isBefore(currentDateTime)) {
            callback(
              new Error(
                __(
                  "Please select a start date that is today or later, unless the schedule repeats"
                )
              )
            );
          }
        }
      }

      callback();
    };
    return {
      rules: {
        schedule_name: [
          { required: true, trigger: "blur", message: __("Name is required") },
          { validator: validateHolidayName, trigger: "blur" }
        ],
        schedule_time_zone: [
          {
            required: true,
            trigger: "blur",
            message: __("Timezone is required")
          }
        ],
        schedule_items: [
          {
            validator: checkScheduleItems,
            trigger: "change"
          }
        ]
      },
      schedule_time_zone: "",
      isSubmitting: false,
      additionalValidateRoute: "holidays",
      isContent: true,
      viewMode: "allow_read",
      forceDeleteEventName: "initiate-force-delete-HolidaySchedule",
      safeDeleteEventName: "initiate-safe-delete-HolidaySchedule"
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapState("folders", {
      selectedFolderId: state => state.selectedFolderId
    })
  },
  methods: {
    ...mapActions("holidayschedules", {
      createHolidaySchedule: "createHolidaySchedule",
      updateHolidaySchedule: "updateHolidaySchedule",
      updateContent: "updateHolidaySchedule",
      deleteContentMethod: "deleteHolidaySchedule",
      undoDeleteContent: "undoDeleteHolidaySchedule",
      safeDeleteContent: "safeDeleteHolidaySchedule",
      refreshHolidaySchedule: "refreshHolidaySchedule"
    }),

    async handleTimezoneChange(timezone) {
      this.contentForm.schedule_time_zone = timezone;
      this.contentForm.schedule_items.forEach((item, index) => {
        this.$refs.contentForm.validateField(`schedule_items[${index}]`);
      });
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1
              ? this.createHolidaySchedule
              : this.updateHolidaySchedule;
          this.contentForm.ac_id = this.selectedAccountId;
          this.contentForm.folder_id = this.selectedFolderId;
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;
              this.id === -1
                ? this.$message({
                    type: "success",
                    message: __("Holiday added Successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Holiday updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },
    handleRemoveScheduleItem(index) {
      if (index !== -1) {
        this.contentForm.schedule_items.splice(index, 1);
      }
      console.log(index, this.contentForm.schedule_items);
    },
    handleAddScheduleItem() {
      let now_date_time = moment.utc();

      this.contentForm.schedule_items.push({
        item_name: "",
        from_date_time: now_date_time,
        to_date_time: now_date_time,
        is_repeat: 0,
        schedule_repeats: null
      });
    },
    handleUpdateScheduleItem(index, new_schedule_item) {
      console.log("createOrEdit");
      if (index !== -1) {
        let schedule_item = _.nth(this.contentForm.schedule_items, index);
        if (schedule_item) {
          schedule_item = new_schedule_item;
        }
      }
    },

    openMoveToFolderDialog() {
      let content_ids = [this.contentForm.schedule_id];
      if (this.checkedContents.length) {
        content_ids = this.checkedContents.map(item => {
          if (item && item.schedule_id) {
            return item.schedule_id;
          }
        });
      }

      EventBus.$emit("open-move-to-folder-modal", {
        content_ids: [...content_ids],
        content_model: "HolidaySchedule"
      });
    },

    openCheckInUseDialog() {
      EventBus.$emit("open-check-in-use-modal", {
        content_model: "HolidaySchedule",
        content_id: this.contentForm.schedule_id
      });
    },

    refreshCheckInUse() {
      // call backend api
      EventBus.$emit("call-refresh-check-in-use-job", {
        content_model: "HolidaySchedule",
        content_id: this.contentForm.schedule_id,
        content_name: this.contentForm.schedule_name,
        refresh: this.refreshHolidaySchedule
      });
    },

    handleAction(command) {
      switch (command) {
        case EDIT_ACTION:
          this.handleEdit();
          break;
        case DELETE_ACTION:
          this.handleDelete(
            this.contentForm,
            "HolidaySchedule",
            this.contentForm.holiday_id
          );
          break;
        case CHECK_IN_USE_ACTION:
          this.openCheckInUseDialog();
          break;
        case REFRESH_ACTION:
          this.refreshCheckInUse();
          break;
        case MOVE_ACTION:
          this.openMoveToFolderDialog();
          break;
        case RESTORE_ACTION:
          this.restoreContent(this.contentForm);
          break;
      }
    }
  },
  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });

    EventBus.$on(this.safeDeleteEventName, ({ content }) => {
      this.initiateSafeDelete(content);
    });
  },
  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
    EventBus.$off(this.safeDeleteEventName);
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$content-theme-color-white: #ffffff !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/node_common.scss";
::v-deep .el-input-number {
  .el-input-number__decrease:hover {
    color: $content-theme-hover-color;
  }

  .el-input-number__increase:hover {
    color: $content-theme-hover-color;
  }
}

::v-deep .el-checkbox-group {
  .el-checkbox-button.is-focus .el-checkbox-button__inner {
    border-color: $content-theme-hover-color;
  }

  .el-checkbox-button .el-checkbox-button__inner:hover {
    color: $content-theme-hover-color;
  }

  .el-checkbox-button.is-checked .el-checkbox-button__inner:hover {
    color: $content-theme-color-white;
  }
}

::v-deep .el-checkbox {
  :hover {
    border-color: $content-theme-color;
  }

  .el-checkbox__input .el-checkbox__inner {
    border-color: $content-theme-color;
  }

  .el-checkbox__input + .el-checkbox__label {
    color: $content-theme-color;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }
}

::v-deep .el-date-picker {
  :hover {
    color: $content-theme-hover-color;
  }

  .el-date-picker__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }

  .el-date-picker__label {
    color: $content-theme-color;
  }
}

::v-deep .el-select-group__title {
  color: var(--theme-color) !important;
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}

.editContentItemModal {
  padding: 0;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}
</style>
