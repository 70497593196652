<template>
  <div>
    <div class="field-group">
      <div class="titles">
        {{ schedule_time_zone_name }}
      </div>
      <div class="details">
        {{ schedule_time_zone }}
      </div>
    </div>
    <div class="field-group">
      <div class="titles">
        {{ __("Schedule Items") }}
      </div>
      <div
        v-for="schedule_item in this.schedule_items"
        :key="schedule_item.item_id"
      >
        <div class="details">
          {{ schedule_item.item_name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HolidayScheduleInfo",
  components: {},
  props: {
    schedule_time_zone_name: {
      type: String,
      required: false,
      default: __("Schedule Time Zone")
    },
    schedule_time_zone: {
      type: String,
      required: false,
      default: ""
    },
    schedule_items: {
      type: Array,
      required: false,
      default: function() {
        return [];
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/content-edit-info.scss";
</style>
